.whyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 10px;
    width: 300px;
    background-color: rgba(248, 247, 246, 0.7); /* רקע שקוף עם גוון בהיר מאוד */
    backdrop-filter: blur(10px); /* אפקט זכוכית */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(224, 216, 207, 0.3); /* צל עדין בגוון בז' */
    transition: all 0.3s ease;
    border: 1px solid rgba(233, 227, 220, 0.3); /* גבול עדין בגוון בז' בהיר */
}

.whyContainer:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(224, 216, 207, 0.4); /* צל מודגש יותר בעת hover */
}

.icon {
    margin: 2% auto;
    width: 30%;
}

.title {
    font-family: 'AmaticR';
    font-size: 2.5rem;
    color: #555048; /* גוון חום-אפור כהה לכותרת */
    margin: 2% auto;
    text-align: center;
}

.description {
    font-family: 'AssistantR';
    font-size: 1rem;
    color: #7d756d; /* גוון חום-אפור בינוני לטקסט תיאורי */
    text-align: center;
    line-height: 1.2;
    width: 90%;
    margin: 0 auto;
    direction: rtl;
}

/* Max width 350px */
@media (max-width: 350px) {
    .whyContainer {
        padding: 15px;
    }
    .title {
        font-size: 2.2rem;
    }
    .description {
        font-size: 0.9rem;
    }
}

/* 350px - 450px */
@media (min-width: 350px) and (max-width: 450px) {
    .whyContainer {
        padding: 18px;
    }
    .title {
        font-size: 2.3rem;
    }
    .description {
        font-size: 0.95rem;
    }
}

/* 450px - 550px */
@media (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 2.4rem;
    }
}

/* 550px - 650px */
@media (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 2.5rem;
    }
}

/* 650px - 750px */
@media (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 2.6rem;
    }
}

/* 750px - 850px */
@media (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 2.7rem;
    }
}

/* 850px - 950px */
@media (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 2.8rem;
    }
}

/* 950px - 1050px */
@media (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 2.9rem;
    }
}

/* 1050px - 1150px */
@media (min-width: 1050px) and (max-width: 1150px) {
    .title {
        font-size: 3rem;
    }
}

/* 1150px - 1250px */
@media (min-width: 1150px) and (max-width: 1250px) {
    .title {
        font-size: 3.1rem;
    }
}