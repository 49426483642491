.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.title {
  font-size: 2.5rem;
  font-family: 'AmaticR', sans-serif;
  text-align: center;
  margin: 50px auto 1%;
  color: #555048;
  text-shadow: 2px 2px 4px rgba(85, 80, 72, 0.1);
  direction: rtl;
}

.form {
  display: flex;
  flex-direction: column;
  width: 300px;
  direction: rtl;
  border-radius: 8px;
}

.input {
  position: relative;
  padding: 14px 18px;
  margin: 10px 0;
  border: none;
  direction: rtl;
  border-radius: 12px;
  font-size: 1.1rem;
  color: #7d756d;
  font-family: "AssistantR", sans-serif;
  outline: none;
  transition: all 0.4s ease;
  background-color: rgba(248, 247, 246, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(233, 227, 220, 0.5);
  box-shadow: 
    8px 8px 15px rgba(224, 216, 207, 0.4),
    -4px -4px 10px rgba(255, 255, 255, 0.8),
    inset 2px 2px 5px rgba(224, 216, 207, 0.2),
    inset -2px -2px 5px rgba(255, 255, 255, 0.4);
}

.input::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2),
    rgba(248, 247, 246, 0.1)
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.input:hover::before {
  opacity: 1;
}

.input::placeholder {
  color: #7d756d;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.input:focus {
  transform: translateY(-2px);
  background-color: rgba(248, 247, 246, 0.95);
  box-shadow: 
    12px 12px 20px rgba(224, 216, 207, 0.5),
    -6px -6px 12px rgba(255, 255, 255, 0.9),
    inset 1px 1px 3px rgba(224, 216, 207, 0.1),
    inset -1px -1px 3px rgba(255, 255, 255, 0.2);
}

.input:focus::placeholder {
  opacity: 0.5;
  transform: translateX(-5px);
}

.button {
  position: relative;
  margin-top: 20px;
  padding: 14px 18px;
  font-family: "Assistant", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  color: #555048;
  border: none;
  border-radius: 12px;
  background-color: rgba(248, 247, 246, 0.9);
  box-shadow: 
    8px 8px 15px rgba(224, 216, 207, 0.4),
    -4px -4px 10px rgba(255, 255, 255, 0.8),
    inset 2px 2px 5px rgba(224, 216, 207, 0.2),
    inset -2px -2px 5px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease;
  overflow: hidden;
}

.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, 
    rgba(224, 216, 207, 0.4),
    rgba(248, 247, 246, 0.1)
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 12px;
}

.button:hover {
  transform: translateY(-2px);
  background-color: rgba(248, 247, 246, 0.95);
  color: #3d3a35;
  box-shadow: 
    12px 12px 20px rgba(224, 216, 207, 0.5),
    -6px -6px 12px rgba(255, 255, 255, 0.9),
    inset 1px 1px 3px rgba(224, 216, 207, 0.1),
    inset -1px -1px 3px rgba(255, 255, 255, 0.2);
}

.button:hover::before {
  opacity: 1;
}

.button:active {
  transform: translateY(0);
  box-shadow: 
    4px 4px 10px rgba(224, 216, 207, 0.3),
    -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset 2px 2px 5px rgba(224, 216, 207, 0.4),
    inset -2px -2px 5px rgba(255, 255, 255, 0.3);
}

/* Media Queries נשארים אותו דבר */
@media only screen and (max-width: 350px) {
  .title {
    font-size: 2rem;
  }
  
  .form {
    width: 100%;
  }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
  .title {
    font-size: 2rem;
    width: 90%;
  }
  
  .form {
    width: 300px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
  .title {
    font-size: 2.2rem;
    width: 90%;
  }
}

@media only screen and (min-width: 550px) and (max-width: 750px) {
  .title {
    font-size: 2.2rem;
    width: 70%;
  }
}

@media only screen and (min-width: 750px) and (max-width: 950px) {
  .title {
    font-size: 2.5rem;
    width: 90%;
  }
}