.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    position: relative;
    overflow: hidden;
    padding-top: 260px;
  }
  
  .explainContainer {
    position: relative;
    width: 40%;
    z-index: 2;
  }
  
  .imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  
  .topLeftImage,
  .topRightImage,
  .centerImage {
    position: absolute;
    width: 180px;
    height: 240px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    z-index: -1;
  }
  
  .topLeftImage,
  .topRightImage {
    transition: transform 0.1s ease-out;
  }
  
  .topLeftImage {
    top: -135px;
    left: -60px;
    transform: rotate(-20deg);
    background-image: url("../../images/לריאן\ ייעוץ\ שינה.png");
  }
  
  .topRightImage {
    top: -135px;
    right: -60px;
    transform: rotate(20deg);
    height: 0;
    background-image: url("../../images/לריאן\ סנסומוטורי.png");
  }
  
  .centerImage {
    top: -135px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("../../images/לריאן\ לרמן.png");
    z-index: -2;
  }
  
  .explain {
    font-family: "Amatic", sans-serif;
    font-size: 3rem;
    color: #281916;
    padding: 1rem 1.5rem;
    text-align: center;
    background: rgba(255, 255, 255, 0.255);
    backdrop-filter: blur(30px);
    border-radius: 15px;
  }
  
  .contentWrapper {
    padding: 2.5rem;
    max-width: 70%;
    background: rgba(255, 255, 255, 0.255);
    backdrop-filter: blur(30px);
    border-radius: 15px;
    z-index: 1;
  }
  
  .descriptionWrapper {
    font-family: 'AssistantR', sans-serif;
    color: #4a4a4a;  /* Updated to match the dark gray */
    text-align: center;
  }
  
  .description {
    font-size: 1.5rem;
    direction: rtl;
    font-family: 'AssistantR', sans-serif;
    font-weight: 300;
    margin: 0.5rem 0;
    color: #4a4a4a;  /* Added dark gray color */
  }
  
  .description:last-child {
    font-family: "Amatic", sans-serif;
    font-weight: bold;
    font-size: 2.8rem;
    position: relative;
    display: inline-block;
   
  }
  
  .description:last-child::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #eed4c4, #d8c2b5, #eed4c4);  /* Updated gradient with darker and soft brown */
    animation: underlineAnimation 3s infinite;
  }
  
  @keyframes underlineAnimation {
    0%, 100% {
      transform: scaleX(0);
      transform-origin: bottom right;
    }
    50% {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
  
  .bounceArrow {
    animation: bounce 2s infinite;
    color: #6a6a6a;  /* Added darker gray color */
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  /* Media Queries */
  @media (max-width: 350px) {
    .container {
      padding-top: 180px;
    }
    .explainContainer, .contentWrapper {
      max-width: 80%;
    }
    .explain {
      font-size: 1.2rem;
      padding: 1rem;
    }
    .description {
      font-size: 1.1rem;
    }
    .description:last-child {
      font-size: 1.4rem;
    }
    .contentWrapper {
      width: 70%;
    }
    .topLeftImage,
    .topRightImage,
    .centerImage {
      width: 90px;
      height: 120px;
    }
    .topLeftImage {
      top: -75px;
      left: -30px;
    }
    .topRightImage {
      top: -75px;
      right: -30px;
    }
    .centerImage {
      top: -75px;
    }
  }
  
  @media (min-width: 350px) and (max-width: 450px) {
    .container {
      padding-top: 200px;
    }
    .explainContainer, .contentWrapper {
      max-width: 85%;
    }
    .explain {
      font-size: 1.4rem;
    }
    .contentWrapper {
      width: 75%;
      padding: 2rem;
    }
    .description {
      font-size: 1.15rem;
    }
    .description:last-child {
      font-size: 1.5rem;
    }
    .topLeftImage,
    .topRightImage,
    .centerImage {
      width: 110px;
      height: 147px;
    }
    .topLeftImage {
      top: -85px;
      left: -35px;
    }
    .topRightImage {
      top: -85px;
      right: -35px;
    }
    .centerImage {
      top: -85px;
    }
  }
  
  @media (min-width: 450px) and (max-width: 1250px) {
    .container {
      padding-top: calc(220px + (260 - 220) * ((100vw - 450px) / (1250 - 450)));
      gap: calc(1rem + (1.5 - 1) * ((100vw - 450px) / (1250 - 450)));
    }
    .explainContainer {
      max-width: calc(80% + (70 - 80) * ((100vw - 450px) / (1250 - 450)));
    }
    .contentWrapper {
      max-width: calc(75% + (65 - 75) * ((100vw - 450px) / (1250 - 450)));
      padding: calc(2rem + (2.5 - 2) * ((100vw - 450px) / (1250 - 450)));
    }
    .explain {
      font-size: calc(1.6rem + (2 - 1.6) * ((100vw - 450px) / (1250 - 450)));
    }
    .description {
      font-size: calc(1.2rem + (1.5 - 1.2) * ((100vw - 450px) / (1250 - 450)));
    }
    .description:last-child {
      font-size: calc(1.5rem + (1.8 - 1.5) * ((100vw - 450px) / (1250 - 450)));
    }
    .topLeftImage,
    .topRightImage,
    .centerImage {
      width: calc(130px + (180 - 130) * ((100vw - 450px) / (1250 - 450)));
      height: calc(173px + (240 - 173) * ((100vw - 450px) / (1250 - 450)));
    }
    .topLeftImage {
      top: calc(-95px + (-135 + 95) * ((100vw - 450px) / (1250 - 450)));
      left: calc(-40px + (-60 + 40) * ((100vw - 450px) / (1250 - 450)));
    }
    .topRightImage {
      top: calc(-95px + (-135 + 95) * ((100vw - 450px) / (1250 - 450)));
      right: calc(-40px + (-60 + 40) * ((100vw - 450px) / (1250 - 450)));
    }
    .centerImage {
      top: calc(-95px + (-135 + 95) * ((100vw - 450px) / (1250 - 450)));
    }
  }