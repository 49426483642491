.title {
    font-family: "Amatic";
    direction: rtl;
    margin: 2% auto;
    width: 80%;
    text-align: center;
}

/* מתחת ל-350px */
@media (max-width: 350px) {
    .title {
        font-size:2rem;
    }
}

/* 350px - 450px */
@media (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2.4rem;
    }
}

/* 450px - 550px */
@media (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 2.6rem;
    }
}

/* 550px - 650px */
@media (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 2.8rem;
    }
}

/* 650px - 750px */
@media (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 2.9rem;
    }
}

/* 750px - 850px */
@media (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 3rem;
    }
}

/* 850px - 950px */
@media (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 3.2rem;
    }
}

/* 950px - 1050px */
@media (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 3.4rem;
    }
}

/* 1050px - 1150px */
@media (min-width: 1050px) and (max-width: 1150px) {
    .title {
        font-size: 3.5rem;
    }
}

/* 1150px - 1250px */
@media (min-width: 1150px) and (max-width: 1250px) {
    .title {
        font-size: 3.5rem;
    }
}

/* מעל 1250px */
@media (min-width: 1250px) {
    .title {
        font-size: 3.5rem;
    }
}