.buttonWrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  direction: rtl;
}

.button {
  font-family: 'Assistant', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #514438;
  background-color: #e6d9d1;
  border: 1px solid #b3a99a;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(179, 169, 154, 0.2);
}

.button:hover {
  background-color: #e6e2db;
  color: #3f3a33;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(179, 169, 154, 0.3);
}

.button:active {
  background-color: #d8d4cd;
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(179, 169, 154, 0.3);
}

@media (max-width: 350px) {
  .button {
      font-size: 0.9rem;
      padding: 10px 20px;
  }
}

/* 350px - 450px */
@media (min-width: 350px) and (max-width: 450px) {
  .button {
      font-size: 0.95rem;
      padding: 11px 22px;
  }
}

/* 450px - 550px */
@media (min-width: 450px) and (max-width: 550px) {
  .button {
      font-size: 1rem;
      padding: 12px 24px;
  }
}

/* 550px - 650px */
@media (min-width: 550px) and (max-width: 650px) {
  .button {
      font-size: 1.05rem;
  }
}

/* 650px - 750px */
@media (min-width: 650px) and (max-width: 750px) {
  .button {
      font-size: 1.1rem;
      padding: 13px 26px;
  }
}

/* 750px - 850px */
@media (min-width: 750px) and (max-width: 850px) {
  .button {
      font-size: 1.15rem;
  }
}

/* 850px - 950px */
@media (min-width: 850px) and (max-width: 950px) {
  .button {
      font-size: 1.2rem;
      padding: 14px 28px;
  }
}

/* 950px - 1050px */
@media (min-width: 950px) and (max-width: 1050px) {
  .button {
      font-size: 1.25rem;
  }
}

/* 1050px - 1150px */
@media (min-width: 1050px) and (max-width: 1150px) {
  .button {
      font-size: 1.3rem;
      padding: 15px 30px;
  }
}

/* 1150px - 1250px */
@media (min-width: 1150px) and (max-width: 1250px) {
  .button {
      font-size: 1.35rem;
  }
}