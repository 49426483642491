.container {
    max-width: 48rem;
    margin: 0 auto;
    padding: 2rem;
}

.serviceWrapper {
    margin-bottom: 2rem;
}

.serviceButton {
    width: 100%;
    padding: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.75rem;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
    position: relative;
}

.serviceButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.serviceButton.active {
    margin-bottom: 0.5rem;
}

.titleContent {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}

.iconWrapper {
    padding: 0.75rem;
    border-radius: 9999px;
    background-color: #f1ece6;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #9a8478;
}

.title {
    font-family: "Amatic";
    font-size: 2rem;
    color: #1e1e1e;
    font-weight: 700;
}

.chevron {
    width: 1.25rem;
    height: 1.25rem;
    color: #9a8478;
    transition: transform 0.3s ease;
    stroke-width: 1.5;
}

.rotate {
    transform: rotate(180deg);
}

.contentWrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
}

.contentWrapper.open {
    max-height: 1000px;
}

.content {
    padding: 2rem;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 
        inset 0 2px 4px rgba(154, 132, 120, 0.05),
        0 1px 2px rgba(154, 132, 120, 0.1);
    border-radius: 0.75rem;
}

.serviceContent {
    text-align: right;
    font-family: 'AssistantR', sans-serif;
    display: flex;
    font-weight: 300;
    flex-direction: column;
    gap: 1.5rem;
}

.description {
    color: #4b5563;
    line-height: 1.8;
    font-size: 1.1rem;
}

.methodBox {
    background: linear-gradient(135deg, rgba(241, 236, 230, 0.7) 0%, rgba(230, 217, 209, 0.8) 100%);
    padding: 2rem;
    border-radius: 1.5rem;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(10px);
    box-shadow: 
        inset 0 2px 15px rgba(255, 255, 255, 0.5),
        inset 0 -2px 15px rgba(154, 132, 120, 0.15),
        0 10px 20px -10px rgba(154, 132, 120, 0.3),
        0 30px 40px -20px rgba(154, 132, 120, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-size: 200% 200%;
    animation: gradientWave 15s ease infinite;
    transition: all 0.3s ease;
}

/* אפקט זכוכית וגרדיאנט */
.methodBox::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: 
        linear-gradient(135deg, 
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0.1) 40%,
            rgba(255, 255, 255, 0.05) 60%,
            rgba(255, 255, 255, 0.1) 100%
        ),
        radial-gradient(circle at 20% 20%, rgba(255, 255, 255, 0.3) 0%, transparent 50%),
        radial-gradient(circle at 80% 80%, rgba(255, 255, 255, 0.3) 0%, transparent 50%);
    opacity: 0.8;
    pointer-events: none;
}

/* אפקט קו צד משודרג */
.methodBox::after {
    content: '';
    position: absolute;
    top: 1.5rem;
    right: 0;
    width: 4px;
    height: calc(100% - 3rem);
    background: linear-gradient(to bottom, 
        rgba(154, 132, 120, 0) 0%,
        rgba(154, 132, 120, 0.3) 30%,
        rgba(154, 132, 120, 0.3) 70%,
        rgba(154, 132, 120, 0) 100%
    );
    border-radius: 4px;
    box-shadow: 
        -2px 0 8px rgba(154, 132, 120, 0.1),
        2px 0 8px rgba(255, 255, 255, 0.2);
}

.methodTitle {
    font-weight: 600;
    color: #9a8478;
    margin-bottom: 1.5rem;
    font-size: 2.3rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-family: 'Amatic', sans-serif;
    position: relative;
    padding-right: 1rem;
}

.methodList {
    list-style-type: none;
    color: #4b5563;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 2rem;
    position: relative;
}

.methodList li {
    position: relative;
    padding-right: 1.5rem;
    transition: transform 0.3s ease;
    opacity: 0;
    animation: fadeInRight 0.6s ease forwards;
    animation-delay: calc(var(--li-index, 0) * 0.1s);
}

/* עיצוב מתוחכם לנקודות */
.methodList li::before {
    content: '';
    position: absolute;
    right: -1.5rem;
    top: 0.5rem;
    width: 8px;
    height: 8px;
    background: transparent;
    border: 2px solid rgba(154, 132, 120, 0.5);
    border-radius: 50%;
    transform: translate(50%, 0);
    transition: all 0.3s ease;
}

/* אפקט הילה בהובר */
.methodList li::after {
    content: '';
    position: absolute;
    right: -1.25rem;
    top: 0.75rem;
    width: 16px;
    height: 16px;
    background: radial-gradient(circle, rgba(154, 132, 120, 0.1) 0%, transparent 70%);
    border-radius: 50%;
    transform: translate(50%, -50%) scale(0);
    transition: transform 0.3s ease;
    pointer-events: none;
}

.methodList li:hover {
    transform: translateX(-4px);
}

.methodList li:hover::before {
    background: #9a8478;
    border-color: #9a8478;
    transform: translate(50%, 0) scale(1.2);
}

.methodList li:hover::after {
    transform: translate(50%, -50%) scale(1);
}

/* Gradient border effect */
.serviceButton::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 0.75rem;
    padding: 2px;
    background: linear-gradient(135deg, #f1ece6 0%, #e6d9d1 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    pointer-events: none;
}

/* אנימציות */
@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes gradientWave {
    0% {
        background-position: 0% 50%;
        box-shadow: 
            inset 0 2px 15px rgba(255, 255, 255, 0.5),
            inset 0 -2px 15px rgba(154, 132, 120, 0.15),
            0 10px 20px -10px rgba(154, 132, 120, 0.3),
            0 30px 40px -20px rgba(154, 132, 120, 0.15);
    }
    50% {
        background-position: 100% 50%;
        box-shadow: 
            inset 0 2px 15px rgba(255, 255, 255, 0.6),
            inset 0 -2px 15px rgba(154, 132, 120, 0.2),
            0 12px 24px -12px rgba(154, 132, 120, 0.35),
            0 35px 45px -25px rgba(154, 132, 120, 0.2);
    }
    100% {
        background-position: 0% 50%;
        box-shadow: 
            inset 0 2px 15px rgba(255, 255, 255, 0.5),
            inset 0 -2px 15px rgba(154, 132, 120, 0.15),
            0 10px 20px -10px rgba(154, 132, 120, 0.3),
            0 30px 40px -20px rgba(154, 132, 120, 0.15);
    }
}

/* Media Queries */
@media (max-width: 1250px) {
    .container { max-width: 90%; }
    .title { font-size: 2.2rem; }
    .methodTitle { font-size: 2.1rem; }
}

@media (max-width: 1150px) {
    .container { max-width: 92%; }
    .title { font-size: 2.1rem; }
    .methodTitle { font-size: 2rem; }
}

@media (max-width: 1050px) {
    .container { max-width: 94%; }
    .title { font-size: 2rem; }
    .methodTitle { font-size: 1.9rem; }
    .description { font-size: 1.05rem; }
}

@media (max-width: 950px) {
    .container {
        max-width: 96%;
        padding: 1.75rem;
    }
    .serviceButton { padding: 1.5rem; }
    .iconWrapper { padding: 0.7rem; }
}

@media (max-width: 850px) {
    .container { padding: 1.5rem; }
    .title { font-size: 1.9rem; }
    .methodTitle { font-size: 1.8rem; }
    .description { font-size: 1rem; }
    .methodBox { padding: 1.75rem; }
}

@media (max-width: 750px) {
    .container { padding: 1.25rem; }
    .serviceButton { padding: 1.25rem; }
    .title { font-size: 1.8rem; }
    .methodTitle {
        font-size: 1.7rem;
        margin-bottom: 1.25rem;
    }
    .methodBox { padding: 1.5rem; }
}

@media (max-width: 650px) {
    .container { padding: 1rem; }
    .title { font-size: 1.7rem; }
    .methodTitle { font-size: 1.6rem; }
    .iconWrapper { padding: 0.6rem; }
    .icon {
        width: 1.3rem;
        height: 1.3rem;
    }
    .methodList { gap: 0.875rem; }
}

@media (max-width: 550px) {
    .title { font-size: 1.6rem; }
    .methodTitle { font-size: 1.5rem; }
    .description {
        font-size: 0.95rem;
        line-height: 1.7;
    }
    .methodBox { padding: 1.25rem; }
    .methodList { padding-right: 1.75rem; }
}

@media (max-width: 450px) {
    .container { padding: 0.75rem; }
    .serviceButton { padding: 1rem; }
    .title { font-size: 1.5rem; }
    .methodTitle { font-size: 1.4rem; }
    .iconWrapper { padding: 0.5rem; }
    .icon {
        width: 1.2rem;
        height: 1.2rem;
    }
    .methodList {
        gap: 0.75rem;
        padding-right: 1.5rem;
    }
}

@media (max-width: 350px) {
    .container { padding: 0.5rem; }
    .serviceButton { padding: 0.875rem; }
    .title { font-size: 1.4rem; }
    .methodTitle { font-size: 1.3rem; }
    .description {
        font-size: 0.9rem;
        line-height: 1.6;
    }
    .methodBox { padding: 1rem; }
    .methodList {
        gap: 0.625rem;
        padding-right: 1.25rem;
    }
    .icon {
        width: 1.1rem;
        height: 1.1rem;
    }
}